document.addEventListener('alpine:init', () => {
    //@ts-ignore
    Alpine.data('clock', () => ({
        time: new Date(),
        init() {
            setInterval(() => {
                this.time = new Date();
            }, 1000)
        },
        padZero(num: number | string, size) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        },
        getTime() {
            return `${this.padZero(this.time.getHours(),2)}:${this.padZero(this.time.getMinutes(),2)}`
        }
    }));
})

//@ts-ignore
window.openFriend = () => {

}